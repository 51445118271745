import Swiper, {Navigation, Pagination, FreeMode, Autoplay } from "swiper";

export function initialSwiper(props) {
    const el = document.querySelector(`${props.name}`)
    if (!el) return

    return new Swiper(props.name, {
        modules: [Navigation, Pagination, FreeMode, Autoplay],
        slidesPerView: 'auto',
        slideDuplicateClass: '-duplicate',
        pagination: {
            el: props.name + ' .swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: props.name + ' .swiper-button-next',
            prevEl: props.name + ' .swiper-button-prev',
        },
        ...props.option
    });
}
