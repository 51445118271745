import React, { LegacyRef, useContext, useRef, useState } from 'react'
import Bay from './popup/Bay'
import ApiService from '../api/ApiService'
import { AuthContext } from '../context'
import { useNavigate } from 'react-router'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { makeDate } from '../utils/utils'
import { Value } from '../api/models/responses'
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import '..//styles/MyCalendar.css'; 

const Generate = () => {
    const [inn, setInn] = useState<string>('')

    const [date, setDate] = useState<Value>(new Date());

    const token = useContext(AuthContext)

    const [politicCheck, setPoliticCheck] = useState<boolean>(false)

    const navigate = useNavigate()

    const buttonHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (inn.length === 12 || inn.length === 10) {
            console.log('отправка запроса')
            let dateString = ''
            if (politicCheck) {
                dateString = makeDate(date)
            }
            // navigate('/', { state: { method: inn } })
            fetchGetReportCategory(dateString)
        }
    }
// '{"result":true, "count":42}'

    async function fetchGetReportCategory(dateString: string) {
        const response = await ApiService.sheduleReportCategory(token, inn, dateString)
        if (response && response.status >= 200 && response.status < 300) {
            
            navigate('/', { state: { method: inn } })
        }
    }
    return (
        <section className="generate">

            <div className="container">
                <h1>Расчет категории благонадёжности</h1>
                <form className="generate-form page-form">
                    <div className="page-form-send">
                        <div className="generate-form-header">
                            <h2>Анализ вероятности взыскания задолжености с юр. лиц</h2>
                        </div>
                        <div className="generate-form-mask">
                            <div className="generate-form-inputs">
                                <div className="input">
                                    <div className="input-label">Введите ИНН должника</div>
                                    <input
                                        className="js-number"
                                        type="text"
                                        name="name"
                                        data-required="validateText"
                                        maxLength={12}
                                        value={inn}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInn(e.target.value)}
                                    />
                                    <div className="input-error">Обязательное поле</div>
                                </div>
                            </div>
                            <label className="politic">
                                <input
                                    type="checkbox"
                                    name="solution"
                                    checked={politicCheck}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPoliticCheck(!politicCheck)}
                                />
                                <span className="politic-wrap">
                                    <span className="politic-box">
                                        <svg>
                                            <use xlinkHref={`/sprite.svg#check`} />
                                        </svg>
                                    </span>
                                    {/* <span className="politic-text">Поставьте галочку, если вами уже получено решение суда (исполнительный лист)</span> */}
                                    <span className="politic-text">Сформировать отчет на дату</span>
                                </span>
                            </label>
                            {politicCheck &&
                                <div style={{ 'marginTop': '10px' }}>
                                    <DatePicker
                                        onChange={setDate}
                                        value={date}
                                        locale="ru-RU"
                                        format="MM/dd/yyyy" 
                                        // className='custom-calendar'
                                    />
                                </div>}
                            <button
                                className="btn btn-red"
                                type="submit"
                                onClick={buttonHandler}
                            >Запустить расчёт</button>
                        </div>
                    </div>
                    <div className="page-form-ok">
                        <div className="generate-form-header">
                            <h2>Отчёт обрабатывается</h2>
                            <p>Составление отчёта может занять время. Чтобы скачать отчёт перейдите во вкладку <a href="/">«История ваших запросов»</a></p>
                        </div>
                    </div>
                </form>
            </div>


            <Bay tp={-3}/>

        </section>
    )
}

export default Generate