import { useContext, useEffect, useState } from 'react'
import Navbar from './Navbar'
import Header from './Header'
import Call from './popup/Call'
import Password from './popup/Password'
import { Outlet, useLocation } from 'react-router'
import { initialSwiper } from "../utils/swiper";
import { Form, changeFile, maskPhone, passwordVisible } from "../utils/form";
import { Anchors } from "../utils/anchors";
import { Modal } from "../utils/modal";
import Swiper from 'swiper';
import { InputElement } from 'imask/esm/index';
import { inputNumberValue } from '../utils/inputNumber'
import { ITariffPlan } from '../api/models/responses'
import ApiService from '../api/ApiService'
import { AuthContext } from '../context'
import { splitPrice } from '../utils/utils'

const DEFAULT_RANGE = [
  { amountOfRequests: 100, price: 38000, daysValid: 365 },
  { amountOfRequests: 200, price: 76000, daysValid: 365 },
  { amountOfRequests: 500, price: 172000, daysValid: 365 },
  { amountOfRequests: 1000, price: 310000, daysValid: 365 },
  { amountOfRequests: 1500, price: 436000, daysValid: 365 },
  { amountOfRequests: 2000, price: 553000, daysValid: 365 },
  { amountOfRequests: 2500, price: 665000, daysValid: 365 },
  { amountOfRequests: 3000, price: 771000, daysValid: 365 },
  { amountOfRequests: 3500, price: 873000, daysValid: 365 },
  { amountOfRequests: 4000, price: 972000, daysValid: 365 },
  { amountOfRequests: 4500, price: 1068000, daysValid: 365 },
  { amountOfRequests: 5000, price: 1161000, daysValid: 365 },
  { amountOfRequests: 5500, price: 1252000, daysValid: 365 },
  { amountOfRequests: 6000, price: 1340000, daysValid: 365 },
  { amountOfRequests: 6500, price: 1426000, daysValid: 365 },
  { amountOfRequests: 7000, price: 1511000, daysValid: 365 },
  { amountOfRequests: 7500, price: 1594000, daysValid: 365 },
  { amountOfRequests: 8000, price: 1675000, daysValid: 365 },
  { amountOfRequests: 8500, price: 1755000, daysValid: 365 },
  { amountOfRequests: 9000, price: 1833000, daysValid: 365 },
  { amountOfRequests: 9500, price: 1910000, daysValid: 365 },
  { amountOfRequests: 10000, price: 1985000, daysValid: 365 },
]

const Layout = ({ userInfo, tariffPlans }: any) => {
  
  const location = useLocation()

  useEffect(() => {
    (function () {
      console.log('layout useefect')
      const rangeSinge = document.querySelectorAll(".range-single");
      if (!rangeSinge.length) return;

      window.initRangeSinge = (i: any) => {
        rangeSinge.forEach((item) => {
          const rangeInput = item.querySelector(".range-input input")!,
            range = item.querySelector(".range-progress");

          rangeLength(rangeInput, range, item);

          rangeInput.addEventListener("input", (e) => {
            // let value = parseInt(rangeInput.value);
            rangeLength(rangeInput, range, item);
          });
        });
      };

      window.initRangeSinge()

      function rangeLength(item: any, range: any, parant: any) {
        let price = parseInt(item.value.replace(/\D/g, ""));
        const val = 100 - ((price - item.min) / (item.max - item.min)) * 100 + "%";
        parant.style.setProperty('--pos', val)
        range.style.right = val
      }
    })();

    const inputRange = [...document.querySelectorAll(".range-single")];
    const forms = [...document.querySelectorAll(".page-form")];
    const inputPhone = [...document.querySelectorAll('input[name="phone"]')];
    const anchors = new Anchors();
    const modal = new Modal();
    const jsNumber = [...document.querySelectorAll('.js-number')]
    const sliderDefault = [{ name: ".popup-bay-checked.swiper" }];
    const closeNavbarBtn = document.querySelector(".popup-close")
    const sliderList: { swiper: Swiper | null, key: string, init: (slide: any) => void }[] = [
      {
        swiper: null,
        key: "package-wrapper",
        init() {
          if (window.innerWidth > 720) {
            if (!this.swiper) this.swiper = initialSwiper({ name: `.${this.key}` }) as Swiper;
          } else {
            if (this.swiper) {
              this.swiper.destroy(true, false);
              this.swiper = null;
            }
          }
        },
      },
    ];
    const navbar = (document.querySelector(".navbar")! as HTMLDivElement);
    if (!location.state?.method && navbar.classList.contains('popup_open')){
      (closeNavbarBtn as HTMLElement).click();
    }
    
    // jsNumber.forEach(i => {
    //   inputNumberValue(i)
    // })

    const platePosition = (parent: any) => {
      const plate = parent.querySelector(".package-plate");
      plate.style.setProperty("--left", null);
      const parentPos = {
        x: parent.getBoundingClientRect().x,
        y: parent.getBoundingClientRect().x + parent.clientWidth,
      };
      const platePos = {
        x: plate.getBoundingClientRect().x,
        y: plate.getBoundingClientRect().x + plate.clientWidth,
      };

      if (platePos.x < parentPos.x)
        plate.style.setProperty("--left", parentPos.x - platePos.x + "px");
      if (platePos.y > parentPos.y)
        plate.style.setProperty("--left", parentPos.y - platePos.y + "px");
    };
    window.addEventListener("load", () => {

      inputPhone.forEach((item) => maskPhone(item));
      sliderDefault.forEach((slide) => initialSwiper(slide));
      anchors.update(window.location.hash);
      passwordVisible();
      changeFile();
      sliderList.forEach((slide) => slide.init(slide));

      inputRange.forEach((item) => {
        setTimeout(() => {
          platePosition(item);
        }, 0);
      });
    });
    forms.forEach((i) => new Form(i));
    window.addEventListener("resize", () => {
      sliderList.forEach((slide) => slide.init(slide));
    });

    const bayUser = [...document.querySelectorAll('[name="user"]')];
    const entityItem = [...document.querySelectorAll("[data-entity]")];
    bayUser.forEach((item) => {
      (item as InputElement).onchange = () => {
        const entity = (item as InputElement).value === "Entity";
        entity
          ? entityItem.forEach((i) => i.classList.add("active"))
          : entityItem.forEach((i) => i.classList.remove("active"));
      };
    });

    const dataBay = [...document.querySelectorAll("[data-bay]")];
    dataBay.forEach((btn) => {
      btn.addEventListener("click", () => {
        const obj = {
          requests: (btn as HTMLElement).dataset.requests!,
          validity: (btn as HTMLElement).dataset.validity!,
          price: (btn as HTMLElement).dataset.price!,
          saving: (btn as HTMLElement).dataset.saving!,
        };

        const popupBay = document.querySelector("#popup-bay")!;
        const itemName = popupBay.querySelectorAll("[data-name]");

        itemName.forEach((item) => {
          const name = (item as InputElement).dataset.name;
          item.tagName === "INPUT"
            ? ((item as InputElement).value = obj[name as keyof typeof obj])
            : (item.textContent = obj[name as keyof typeof obj]);
        });
      });
    });

    inputRange.forEach((item) => {
      const input = item.querySelector("input")!;
      const number = [...item.querySelectorAll('.range-number-item')]

      input.addEventListener("input", () => {
        console.log('input range')
        console.log(tariffPlans)
        const data = DEFAULT_RANGE[Number(input.value) - 1];
        const requests = item.querySelector('[data-name="requests"]')!;
        const validity = item.querySelector('[data-name="validity"]')!;
        const saving = item.querySelector("[data-saving]")!;

        requests.textContent = String(data.amountOfRequests);
        validity.textContent = String(data.daysValid);
        saving.textContent = savingInit(data);

        const btn = item.querySelector("[data-bay]");
        if (btn) {
          (btn as HTMLElement).dataset.requests = splitPrice(String(data.amountOfRequests));
          
          (btn as HTMLElement).dataset.price = splitPrice(String(data.price)) + " ₽";
          (btn as HTMLElement).dataset.validity = String(data.daysValid) + " дней";
          (btn as HTMLElement).dataset.saving = savingInit(data);
        }
        number.forEach((i, idx) => {
          idx < Number(input.value) ? i.classList.add('active') : i.classList.remove('active')
        })

        updateInput(item, data);

        setTimeout(() => {
          platePosition(item);
        }, 0);
      });
    });
    const savingInit = (data: any) => {
      const requests = data.amountOfRequests
      const price = data.price
      return (requests * 390 - price).toLocaleString();
    };
    const updateInput = (parent: any, data: any) => {
      const inputsHidden = [
        ...parent
          .closest(".package-range-item")
          .querySelectorAll('[type="hidden"]'),
      ];
      inputsHidden.forEach((item) => {
        item.value = data[item.name];
      });
    };
  }, [location.pathname])
  return (
    <div className="wrapper" id="app">
      <Navbar userInfo={userInfo} />
      <main>
        <Header userInfo={userInfo}/>
        <div className="wrapper-scroll">
          <Outlet />
        </div>
      </main>
      <Call />
      <Password />
    </div>
  )
}

export default Layout