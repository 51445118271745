import Bay from '../components/popup/Bay'
import Tariff from '../components/popup/Tariff'
import PackageSlide from '../components/PackageSlide'
import PackageRange from '../components/PackageRange'
import { useContext, useEffect, useRef, useState } from 'react'
import { ITariffPlan } from '../api/models/responses'
import { AuthContext } from '../context'
import ApiService from '../api/ApiService'

interface IPopularTp {
    amountOfRequests: Number;
    id: Number;
}
function Page3() {

    const [popularTariff, setPopularTariff] = useState<IPopularTp>({ 'amountOfRequests': 0, 'id': 0 })

    const [tariffPlans, setTariffPlans] = useState<ITariffPlan[]>([])

    const [headerButton, setHeaderButton] = useState<boolean>(false)

    const token = useContext(AuthContext)

    const [rangeValue, setRangeValue] = useState<string>('4')

    const [tp, setTp] = useState<Number>(-3)

    const [currentRequests, setCurrentRequests] = useState<Number>(1000)

    const range = useRef<HTMLDivElement>(null)

    async function fetchGetTariffPlans(token: string) {
        const response = await ApiService.tariffPlans(token)
        setTariffPlans(response)
        console.log(response)
    }

    useEffect(() => {
        fetchGetTariffPlans(token)
        document.addEventListener('click', (e:any)=>{
            if ((e.target as HTMLElement).closest(".btn") && (e.target as HTMLElement).textContent === "Купить пакеты проверки") {
                setHeaderButton(true)
            }
        })
    }, [])

    useEffect(()=> {
        if (headerButton){
            setTp(popularTariff.id)
        }
    }, [headerButton])
    const click = (e:any) => {
        if ((e.target as HTMLElement).closest(".btn")) {
            const el = (e.target as HTMLElement)
            if (el.textContent === 'Купить' && (!el.classList.contains('btn-blue-border'))) {
                const rangeItems = range.current!.children
                const currentItem = (rangeItems[Number(rangeValue) - 1] as HTMLDivElement)
                const requests = Number(currentItem.dataset.value!.replace(' ', ''))
                console.log(tariffPlans.filter((el) => el.amountOfRequests === requests)[0].id + '----')
                setTp(tariffPlans.filter((el) => el.amountOfRequests === requests)[0].id)
                
            }
            else if (el.textContent !== 'Оплатить онлайн'){
                setTp(popularTariff.id)
            }
            setHeaderButton(false)
        }
    }

    return (
        <section className="package" onClick={click}>
            <div className="container">
                <h1>Ваш текущий пакет</h1>
                <div className="package-header">
                    <h2>Ваши действующие тарифы и выгодные предложения</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                </div>
                <PackageSlide
                    tariffPlans={tariffPlans}
                    setTp={setTp}
                    popularTariff={popularTariff}
                    setPopularTariff={setPopularTariff}
                />
                <PackageRange
                    currentRequests={currentRequests}
                    setCurrentRequests={setCurrentRequests}
                    tariffPlans={tariffPlans}
                    rangeValue={rangeValue}
                    setRangeValue={setRangeValue}
                    range={range}
                />
            </div>
            <Bay tp={tp} />
            <Tariff tariffPlans={tariffPlans} />
        </section>
    )
}

export default Page3