import React, { useContext, useEffect, useRef, useState } from 'react'
import Bay from './popup/Bay'
import { inputsAddReadonly, inputsRemoveReadonly } from '../utils/utils'
import { ICompanyInfo } from '../api/models/responses'
import ApiService from '../api/ApiService'
import { AuthContext } from '../context'

const Settings = () => {
    const token = useContext(AuthContext)

    const [type, setType] = useState<string>('company')

    const [edit, setEdit] = useState<boolean>(false)
 
    const container1 = useRef<HTMLDivElement>(null)
    const container2 = useRef<HTMLDivElement>(null)

    const [depends, setDepends] = useState<number[]>([])

    const [companyInfo, setCompanyInfo] = useState<ICompanyInfo>({
        accountId: '',
        taxCode: '',
        secondSideName: '',
        legalAddress: '',
        kpp: '',
        bik: '',
        bankName: '',
        bankCorrAccount: '',
    })

    const editCompanyHandler = () => {
        setEdit(true)
        const inputs1 = container1.current!.children
        const inputs2 = container2.current!.children
        inputsRemoveReadonly(inputs1)
        inputsRemoveReadonly(inputs2)
    }

    const saveHandler = () => {
        setEdit(false)
        const inputs1 = container1.current!.children
        const inputs2 = container2.current!.children
        inputsAddReadonly(inputs1)
        inputsAddReadonly(inputs2)
        fetchSaveCompany()
    }


    async function fetchGetCompnayInfo() {
        const response = await ApiService.userInfo(token)
        if (response.companiesId) {
            if (response.companiesId.secondSideName) {
                setCompanyInfo(response.companiesId)
            }
        }
    }

    async function fetchSaveCompany() {
        console.log(companyInfo)
        const response = await ApiService.saveCompany(token, companyInfo)
        console.log(response?.status)
        setDepends([...depends, 0])
    }

    useEffect(() => {
        fetchGetCompnayInfo()
    }, [])
    return (
        <section className="settings">
            <div className="container">
                <h1>Настройки и смена пароля</h1>
                <div className="container-buttons">
                    <button
                        className={type === 'main' ? 'active' : 'disabled'}
                        // onClick={(e) => setType('main')}
                    >
                        Общие данные
                    </button>
                    <button
                        className={type === 'main' ? 'disabled' : 'active'}
                        onClick={(e) => setType('company')}
                    >
                        Реквизиты
                    </button>
                </div>
                {type === 'main' ?
                    <div className="settings-form">
                        <div className="settings-form-header">
                            <h2>Персональные данные</h2>
                        </div>
                        <div className="settings-form-group">
                            <div className="settings-form-inputs">
                                <div className="input">
                                    <div className="input-title">Фамилия</div>
                                    <div className="input-wrap">
                                        <input type="text" name="name" value="Петров" readOnly />
                                    </div>
                                </div>
                                <div className="input">
                                    <div className="input-title">Имя</div>
                                    <div className="input-wrap">
                                        <input type="text" name="name" value="Александр" readOnly />
                                    </div>
                                </div>
                                <div className="input">
                                    <div className="input-title">Номер телефона</div>
                                    <div className="input-wrap">
                                        <input type="text" name="name" value="+7 914 120 90 65" readOnly />
                                    </div>
                                </div>
                                <div className="input">
                                    <div className="input-title">E-mail</div>
                                    <div className="input-wrap">
                                        <input type="text" name="name" value="infoemail@mail.ru" readOnly />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="settings-form-group">
                            <div className="settings-form-header">
                                <h3>Данные для входа</h3>
                            </div>
                            <div className="settings-form-inputs">
                                <div className="input">
                                    <div className="input-title">Логин</div>
                                    <div className="input-wrap">
                                        <input type="text" name="name" value="infologin" readOnly />
                                    </div>
                                </div>
                                <div className="input">
                                    <div className="input-title">Пароль</div>
                                    <div className="input-wrap">
                                        <input type="password" name="name" value="infologin" readOnly />
                                        <div className="input-right">
                                            <div className="input-eye btn">
                                                <svg>
                                                    <use xlinkHref={`/sprite.svg#eye`} />
                                                </svg>
                                            </div>
                                            <div className="input-edit btn btn-gray" data-modal="popup-password">
                                                <svg>
                                                    <use xlinkHref={`/sprite.svg#edit`} />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :

                    <div className="settings-form">
                        <div className="settings-form-header">
                            <h2>Данные организации</h2>
                            <div
                                className={edit ? "input-edit btn btn-gray company-edit active" : "input-edit btn btn-gray company-edit"}
                                onClick={editCompanyHandler}
                            >
                                <svg>
                                    <use xlinkHref={`/sprite.svg#edit`} />
                                </svg>
                            </div>
                        </div>

                        <div className="settings-form-group" >
                            <div className="settings-form-inputs" ref={container1}>
                                <div className="input">
                                    <div className="input-title">Наименование</div>
                                    <div className="input-wrap">
                                        <input
                                            type="text"
                                            name="name"
                                            value={companyInfo.secondSideName}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCompanyInfo({ ...companyInfo, secondSideName: e.target.value })}
                                            readOnly
                                        />

                                    </div>
                                </div>

                                <div className="input">
                                    <div className="input-title">ИНН</div>
                                    <div className="input-wrap">
                                        <input
                                            type="number"
                                            name="name"
                                            value={companyInfo.taxCode}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                if (e.target.value.length <= 12) setCompanyInfo({ ...companyInfo, taxCode: e.target.value })
                                            }}

                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="input">
                                    <div className="input-title">КПП</div>
                                    <div className="input-wrap">
                                        <input
                                            type="number"
                                            name="name"
                                            value={companyInfo.kpp}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                if (e.target.value.length <= 9) setCompanyInfo({ ...companyInfo, kpp: e.target.value })
                                            }}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="input">
                                    <div className="input-title">Адрес</div>
                                    <div className="input-wrap">
                                        <input
                                            type="text"
                                            name="name"
                                            value={companyInfo.legalAddress}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCompanyInfo({ ...companyInfo, legalAddress: e.target.value })}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="settings-form-group" >
                            <div className="settings-form-header">
                                <h3>Банковские реквизиты</h3>
                            </div>
                            <div className="settings-form-inputs" ref={container2}>
                                <div className="input">
                                    <div className="input-title">Расчётный счёт</div>
                                    <div className="input-wrap">
                                        <input
                                            type="number"
                                            name="name"
                                            value={companyInfo.accountId}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                if (e.target.value.length <= 20) setCompanyInfo({ ...companyInfo, accountId: e.target.value })
                                            }}
                                            readOnly />
                                    </div>
                                </div>
                                <div className="input">
                                    <div className="input-title">Кор. счёт</div>
                                    <div className="input-wrap">
                                        <input
                                            type="number"
                                            name="name"
                                            value={companyInfo.bankCorrAccount}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                if (e.target.value.length <= 20) setCompanyInfo({ ...companyInfo, bankCorrAccount: e.target.value })
                                            }}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="input">
                                    <div className="input-title">БИК</div>
                                    <div className="input-wrap">
                                        <input
                                            type="number"
                                            name="name"
                                            value={companyInfo.bik}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                if (e.target.value.length <= 11) setCompanyInfo({ ...companyInfo, bik: e.target.value })
                                            }}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className="input">
                                    <div className="input-title">Название банка</div>
                                    <div className="input-wrap">
                                        <input
                                            type="text"
                                            name="name"
                                            value={companyInfo.bankName}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCompanyInfo({ ...companyInfo, bankName: e.target.value })}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {edit &&
                            <div className="btn btn-red save" onClick={saveHandler}>
                                <span>Сохранить данные</span>
                            </div>
                        }

                    </div>
                }

            </div>
            <Bay tp={-3} depends={depends}/>
        </section>
    )
}
export default Settings