import {bodyUnLock, bodyLock} from "./lock";
export class Modal {
    constructor() {
        this.id = null
        this.form = null
        this.create = null
        this.modals = []
    }
    init(props) {
        const btn = props.closest('[data-modal]')
        const close = props.classList.contains('popup') || props.closest('.js-close') || props.classList.contains('js-backdrop')
        const clickDocument = props.closest('.popup')
        const popupAtive = document.querySelector('.popup_open .js-close')

        if (btn) this.click(btn)
        if (close) return this.close(props)
        if (!clickDocument && popupAtive) return this.close(popupAtive)


    }
    click(btn) {
        this.id = btn.dataset.modal
        this.form = btn.dataset.form
        this.create = btn.dataset.create

        this.open()
    }
    open() {
        const modal = document.getElementById(this.id)
        if (!modal) return
        modal.classList.add('popup_open')
        if (this.form) this.formName(modal)
        if (this.create) this.createModal(modal)
        modal.onanimationend = () => null

        bodyLock()
    }
    close(props) {
        const modal = props.closest('.popup')
        const remove = modal.dataset.remove
        modal.classList.add('popup_close')

        modal.onanimationend = () => {
            modal.classList.remove('popup_open', 'popup_close')
            if (remove) modal.remove()

            bodyUnLock()
        }
    }
    createModal(modal) {
        const content = document.getElementById(this.create)

        if (!content) return;

        const jsCreate = modal.querySelector('[data-create]')
        const jsCreateBlock = modal.querySelector('[data-create-block]')


        if (jsCreateBlock) jsCreateBlock.remove()

        const clone = content.cloneNode(true)
        const lazy = [...clone.querySelectorAll('[data-lazy]')]

        lazy.forEach(i => i.src = i.dataset.src)

        clone.style.display = null
        jsCreate.scrollTop = 0

        jsCreate.insertAdjacentElement('afterbegin', clone)
    }
    formName(modal) {
        const input = modal.querySelector('[name="form-name"]')
        if (input) input.value = this.form
    }
}
