import React from 'react'
interface IProps {
    userInfo: {
        lastName: string;
        firstName: string;
    }
}
function Header({ userInfo }: IProps) {
    return (
        <header className="header">
            <div className="container">
                <div className="header-wrapper">
                    <div className="header-logo">
                        <a href="/">
                            <img src="/img/logo.svg" alt="" />
                        </a>
                    </div>
                    <div className="btn btn-red" data-modal="popup-bay" data-bay="data-bay" data-saving="80 000" data-requests="1 000" data-validity="12 месяцев" data-price="310 000 ₽">
                        <svg>
                            <use xlinkHref={`/sprite.svg#folders`} />
                        </svg>
                        <span>Купить пакеты проверки</span>
                    </div>
                    <div className="btn btn-gray">
                        <svg>
                            <use xlinkHref={`/sprite.svg#user`} />
                        </svg>
                        {userInfo && <span>{userInfo.firstName} {userInfo.lastName}</span>}
                    </div>
                    <div className="header-burger" data-modal="popup-navbar">
                        <svg>
                            <use xlinkHref={`/sprite.svg#burger`} />
                        </svg>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header