import React, { FC } from 'react'

const Call:FC = () => {
  return (
    <div className="popup" id="popup-call">
    <form className="popup-call page-form" action="#">
    <div className="popup-close popup-close_white js-close">
      <svg>
        <use xlinkHref="/sprite.svg#close"></use>
      </svg>
    </div>
    <div className="page-form-send">
      <div className="popup-call-header">
        <h3>Оставьте заявку для связи с менеджером</h3>
      </div>
      <div className="popup-call-inputs">
        <div className="input">
          <div className="input-label">Имя</div>
          <input type="text" name="name"/>
        </div>
        <div className="input">
          <input type="tel" name="phone" placeholder="+7 ___ ___ ____" data-required="validatePhone"/>
          <div className="input-error">Обязательное поле</div>
        </div>
        <div className="input">
          <div className="input-label">Задать вопрос</div>
          <textarea name="comment"> </textarea>
        </div>
      </div>
      <button className="btn btn-red" type="submit">Оставить заявку</button>
      <label className="politic politic_required">
        <input type="checkbox" name="politic" defaultChecked={true}/><span className="politic-wrap"><span className="politic-box">
            <svg>
                <use xlinkHref={`/sprite.svg#check`} />
            </svg>
            </span><span className="politic-text">Согласен и принимаю условия <a href="#" target="_blank">пользовательского соглашения</a> и ознакомлен с условиями <a href="#" target="_blank">личной оферты</a></span></span>
      </label>
    </div>
    <div className="page-form-ok">
      <div className="popup-call-header">
        <h2>Ваша заявка отправлена</h2>
        <p>В скором времени наш менеджер свяжется с вами. Пока ожидаете звонка менеджера ознакомьтесь с актуальными пакетами проверки</p>
      </div>
    </div>
  </form>
</div>
  )
}

export default Call