import { Value } from "../api/models/responses";


export const splitPrice = (price: string) => {
    let strPrice = price

    const len = strPrice.length
    for (let i = 1; i < len / 3; i++) {
        strPrice = strPrice.substring(0, len - 3 * i) + ' ' + strPrice.substring(len - 3 * i);
    }
    return strPrice
}
export const imgStatus = (id: number) => id ? 'ok' : 'error'

export const setActive = ({ isActive }: { isActive: boolean }) => isActive ? "navbar-nav-link active" : "navbar-nav-link"

export const spellingRequests = (requests: number) => {
    if (requests <= 20 && requests >= 5) {
        return 'запросов'
    }
    else if (String(requests)[-1] === '1') {
        return 'запрос'
    }
    if (Number(String(requests)[-1]) >= 2 && Number(String(requests)[-1]) <= 4) {
        return 'запроса'
    }
    else {
        return 'запросов'
    }
}

export const inputs_init = (inputs: HTMLCollection) => {
    for (let i = 0; i < inputs!.length; i++) {
        inputs?.item(i)?.classList.add("input_focus")
        inputs?.item(i)?.children.item(1)?.setAttribute('readonly', '')
    }
}

export const validateText = (input: HTMLInputElement) => {
    const parent = input.closest(".input");
    const error = parent!.querySelector(".input-error");
    if (!input.value.length) {
        parent!.classList.add("error");
        if (error) error.textContent = "Обязательное поле";
        return true;
    } else {
        parent!.classList.remove("error");
        return false;
    }
}

export const inputsRemoveReadonly = (inputs: HTMLCollection) => {
    for (let i = 0; i < inputs!.length; i++) {
        inputs!.item(i)?.querySelector('input')!.removeAttribute('readonly')
    }
}

export const inputsAddReadonly = (inputs: HTMLCollection) => {
    for (let i = 0; i < inputs!.length; i++) {
        inputs!.item(i)?.querySelector('input')!.setAttribute('readonly', '')
    }
}

export const checkValidate = (inputWrapper: React.RefObject<HTMLDivElement>) => {
    const input1 = inputWrapper.current!.children.item(1)!.children
    const input2 = inputWrapper.current!.children.item(3)!.children
    const isErrors = []
    for (let i = 0; i < input1!.length; i++) {
        const isError1 = validateText(input1!.item(i)!.children.item(1) as HTMLInputElement)
        const isError2 = validateText(input2!.item(i)!.children.item(1) as HTMLInputElement)
        isErrors.push(isError1, isError2)
    }
    return isErrors
}

export const makeDate = (date: Value) => {
    if (date instanceof Date) {
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const monthString = month < 10 ? `0${month}` : `${month}`
        const day = date.getDate();
        const dayString = day < 10 ? `0${day}` : `${day}`
        return `${date.getFullYear()}-${monthString}-${dayString}`
    }
    else {
        return ''
    }
}

export const makeParamsReport = (inn: string, dateString: string) => {
    if (dateString.length !== 0) {
        return {
            'inn': inn,
            'date': dateString
        }
    }
    else {
        return {
            'inn': inn,
        }
    }
}
export const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Добавляем 1, потому что месяцы начинаются с 0
    const day = String(today.getDate()).padStart(2, '0');

    return `${day}.${month}.${year}`;
}