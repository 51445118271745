import React, { useContext, useRef, useState } from 'react'
import { ITariffPlan } from '../../api/models/responses';
import TariffRange from '../TariffRange';
import { AuthContext } from '../../context';
import ApiService from '../../api/ApiService';
interface IProps {
    tariffPlans: ITariffPlan[];
}
const Tariff = ({ tariffPlans }: IProps) => {
    const [currentRequests, setCurrentRequests] = useState<Number>(1000)

    const range = useRef<HTMLDivElement>(null)

    const [rangeValue, setRangeValue] = useState<string>('4')

    const token = useContext(AuthContext)

    const [politicCheck, setPoliticCheck] = useState<boolean>(true)

    async function fetchBuyTariffPlan(tp: number) {
        const response = await ApiService.purchase(token, tp)
        console.log(response)
        if (response) {
            window.open(
                response
            );
        }
    }

    const handlerBuyTp = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (politicCheck) {
            const rangeItems = range.current!.children
            const currentItem = (rangeItems[Number(rangeValue) - 1] as HTMLDivElement)
            const requests = Number(currentItem.dataset.value!.replace(' ', ''))
            const tp = tariffPlans.filter((el) => el.amountOfRequests === requests)[0].id
            console.log(`Покупка тарифа с id ${tp}`)
            fetchBuyTariffPlan(tp)
        }
    }

    const getCurrentTariff = (requests: Number) => {
        return tariffPlans.filter((el) => el.amountOfRequests === requests)
    }

    const changeRange = (e: React.FormEvent<HTMLInputElement>) => {
        setRangeValue(e.currentTarget.value)
        const rangeItems = range.current!.children
        const currentItem = (rangeItems[Number(e.currentTarget.value) - 1] as HTMLDivElement)
        const requests = Number(currentItem.dataset.value!.replace(' ', ''))
        setCurrentRequests(requests)
        console.log(getCurrentTariff(requests)[0].id)

    }
    return (
        <div className="popup" id="popup-tariff">
            <form className="popup-bay page-form" action="#">
                <div className="popup-close popup-close_white js-close">
                    <svg>
                        <use xlinkHref="/sprite.svg#close"></use>
                    </svg>
                </div>
                <div className="popup-bay-header">
                    <h2>Подбор индивидуального тарифа</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
                </div>
                <div className="package-range-item">
                    <input type="hidden" name="requests" value="1 000" data-name="requests" />
                    <input type="hidden" name="validity" value="12 месяцев" data-name="validity" />
                    <input type="hidden" name="price" value="310 000 ₽" data-name="price" />
                    <div className="package-range-progress">

                        <TariffRange
                            isPermanent={false}
                            range={range}
                            tariffPlans={tariffPlans}
                            rangeValue={rangeValue}
                            changeRange={changeRange}
                            currentRequests={currentRequests}
                        />
                    </div>
                </div>
                <div className="popup-tariff-form">
                    <h3>Оставить заявку</h3>
                    <div className="popup-tariff-inputs">
                        <div className="input">
                            <div className="input-label">Имя</div>
                            <input type="text" name="name" />
                        </div>
                        <div className="input">
                            <div className="input-label">E-mail</div>
                            <input type="text" name="email" data-required="validateEmail" />
                            <div className="input-error">Обязательное поле</div>
                        </div>
                        <div className="input">
                            <input type="tel" name="phone" placeholder="+7 ___ ___ __ __" data-required="validatePhone" />
                            <div className="input-error">Обязательное поле</div>
                        </div>
                        <button
                            className="btn btn-red"
                            type="submit"
                            onClick={handlerBuyTp}
                        >Оплатить онлайн</button>
                    </div>
                    <label className="politic politic_required">
                        <input
                            type="checkbox"
                            name="politic"
                            checked={politicCheck}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPoliticCheck(!politicCheck)}
                        /><span className="politic-wrap"><span className="politic-box">
                            <svg>
                                <use xlinkHref={`/sprite.svg#check`} />
                            </svg>
                        </span><span className="politic-text">Согласен и принимаю условия <a href="#" target="_blank">пользовательского соглашения</a> и ознакомлен с условиями <a href="#" target="_blank">личной оферты</a></span></span>
                    </label>
                </div>
            </form>
        </div>
    )
}

export default Tariff