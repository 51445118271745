import React from 'react'

const Password = () => {
  return (
    <div className="popup" id="popup-password">
    <form className="popup-password page-form" action="#">
        <div className="popup-close popup-close_white js-close">
        <svg>
            <use xlinkHref="/sprite.svg#close"></use>
        </svg>
        </div>
        <div className="page-form-send">
        <div className="popup-password-header">
            <h2>Сменить пароль</h2>
        </div>
        <div className="popup-password-inputs">
            <div className="input">
            <div className="input-label">Текущий пароль</div>
            <input type="password" name="password"/>
            <div className="input-right">
                <div className="input-eye btn">
                <svg>
                    <use xlinkHref={`/sprite.svg#eye`} />
                </svg>
                </div>
            </div>
            </div>
            <div className="popup-password-description">Пароль должен быть не менее 6 и не более 15 символов в длину, и содержать знак подчеркивания, цифры и буквы</div>
            <div className="input">
            <div className="input-label">Новый пароль</div>
            <input type="password" name="password-new"/>
            <div className="input-right">
                <div className="input-eye btn">
                <svg>
                    <use xlinkHref={`/sprite.svg#eye`} />
                </svg>
                </div>
            </div>
            </div>
            <div className="input">
            <div className="input-label">Повторите новый пароль</div>
            <input type="password" name="password-repeat"/>
            <div className="input-right">
                <div className="input-eye btn">
                <svg>
                    <use xlinkHref={`/sprite.svg#eye`} />
                </svg>
                </div>
            </div>
            </div>
        </div>
        <button className="btn btn-red" type="submit">Сменить пароль</button>
        </div>
        <div className="page-form-ok">
        <div className="popup-password-header">
            <h2>Ваш пароль успешно изменен</h2>
            <p>Пароль изменён</p>
        </div>
        </div>
    </form>
    </div>
  )
}
export default Password