import React, { useRef, useState } from 'react'
import { ITariffPlan } from '../api/models/responses'
import TariffRange from './TariffRange';

interface IProps {
  tariffPlans: ITariffPlan[];
  rangeValue: string;
  setRangeValue: React.Dispatch<React.SetStateAction<string>>;
  currentRequests: Number;
  setCurrentRequests: React.Dispatch<React.SetStateAction<Number>>;
  range: React.RefObject<HTMLDivElement>;
}
const PackageRange = ({ tariffPlans, rangeValue, setRangeValue, currentRequests, setCurrentRequests, range }: IProps) => {

  const changeRange = (e: React.FormEvent<HTMLInputElement>) => {
    setRangeValue(e.currentTarget.value)
    const rangeItems = range.current!.children
    const currentItem = (rangeItems[Number(e.currentTarget.value) - 1] as HTMLDivElement)
    const requests = Number(currentItem.dataset.value!.replace(' ', ''))
    setCurrentRequests(requests)
  }

  return (
    <div className="package-range">
      <div className="package-header">
        <h2>Купите дополнительные пакеты под себя</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
      </div>
      <div className="package-range-item">
        <div className="package-range-progress">
          <TariffRange
            isPermanent={true}
            range={range}
            tariffPlans={tariffPlans}
            rangeValue={rangeValue}
            changeRange={changeRange}
            currentRequests={currentRequests}
          />
        </div>
      </div>
    </div>
  )
}
export default PackageRange