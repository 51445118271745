import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import ApiService from '../../api/ApiService';
import { AuthContext } from '../../context';
import { ICompanyInfo } from '../../api/models/responses';
import { checkValidate, inputs_init } from '../../utils/utils';

interface IProps {
  tp: Number;
  depends?: number[];
}
const Bay: FC<IProps> = ({ tp, depends }) => {

  const token = useContext(AuthContext)

  const inputWrapper = useRef<HTMLDivElement>(null)

  const [radio, setRadio] = useState<string>('Individual')

  const [politicCheck, setPoliticCheck] = useState<boolean>(true)

  const [companyInfo, setCompanyInfo] = useState<ICompanyInfo>({
    accountId: '',
    taxCode: '',
    secondSideName: '',
    legalAddress: '',
    kpp: '',
    bik: '',
    bankName: '',
    bankCorrAccount: '',
  })

  async function fetchGetCompnayInfo() {
    const response = await ApiService.userInfo(token)
    if (response.companiesId) {
      setCompanyInfo(response.companiesId)
      inputs_init(inputWrapper.current!.children.item(1)!.children)
      inputs_init(inputWrapper.current!.children.item(3)!.children)
    }
  }


  async function fetchBuyTpCompany() {
    console.log(companyInfo)
    const response = await ApiService.purchaseCompany(token, tp)
    console.log(response)
  }

  async function fetchBuyTPIndividual() {
    const response = await ApiService.purchase(token, tp)
    console.log(response)
    if (response) {
      window.open(
        response
      );
    }
  }

  const handlerBuyTp = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (politicCheck) {
      console.log(`Покупка тарифа с id ${tp}`)

      if (radio === "Individual") {
        fetchBuyTPIndividual()
      }
      else {
        const isErrors = checkValidate(inputWrapper)
        if (!isErrors.includes(true)) {
          
          fetchBuyTpCompany()
          
        }

      }
    }
  }

  const onOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRadio(e.target.value)
    console.log(companyInfo)
  }

  useEffect(() => {
    fetchGetCompnayInfo()
  }, [depends])

  return (
    <div className="popup" id="popup-bay">
      <form className="popup-bay page-form" action="#">
        <div className="popup-close popup-close_white js-close">
          <svg>
            <use xlinkHref="/sprite.svg#close"></use>
          </svg>
        </div>
        <h2>Оформление заказа на индивидуальный тариф</h2>
        <div className="popup-bay-header">
          <h3>Состав заказа / 1 шт</h3>
          <p>Индивидуальный пакет на <span data-name="requests">1 000</span> запросов, период действия <span data-name="validity">12 месяцев</span></p>
        </div>
        <div className="popup-bay-values">
          <div className="popup-bay-item">
            <div className="popup-bay-span">Кол-во запросов</div>
            <div className="popup-bay-value" data-name="requests">1 000 шт</div>
            <input type="hidden" name="requests" value="1 000" data-name="requests" />
          </div>
          <div className="popup-bay-item">
            <div className="popup-bay-span">Период действия</div>
            <div className="popup-bay-value" data-name="validity">12 месяцев</div>
            <input type="hidden" name="validity" value="12 месяцев" data-name="validity" />
          </div>
          <div className="popup-bay-item">
            <div className="popup-bay-span">Стоимость</div>
            <div className="popup-bay-value" data-name="price">310 000 ₽ </div>
            <input type="hidden" name="price" value="310 000 ₽" data-name="price" />
          </div>
        </div>
        <div className="popup-bay-promo input">
          <div className="input-label">Ввести промокод</div>
          <div className="input-wrap">
            <input type="text" name="promo" />
            <div className="input-right">
              <div className="input-promo btn btn-gray">
                <svg>
                  <use xlinkHref={`/sprite.svg#check`} />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="popup-bay-header">
          <h3>Сумма заказа</h3>
        </div>
        <div className="popup-bay-radios swiper">
          <div className="swiper-wrapper">
            <label className="swiper-slide popup-bay-radio">
              <input
                type="radio"
                name="user"
                value="Individual"
                checked={radio === "Individual"}
                onChange={onOptionChange}
              />
              <div className="popup-bay-radio-wrap">
                <svg>
                  <use xlinkHref={`/sprite.svg#radio`} />
                </svg>
                <span>Физическое лицо</span>
              </div>
            </label>
            <label className="swiper-slide popup-bay-radio">
              <input
                type="radio"
                name="user"
                value="Entity"
                checked={radio === "Entity"}
                onChange={onOptionChange}
              />
              <div className="popup-bay-radio-wrap">
                <svg>
                  <use xlinkHref={`/sprite.svg#radio`} />
                </svg>
                <span>Юридическое лицо</span>
              </div>
            </label>
          </div>
        </div>
        <div className="popup-bay-info">
          <div className="popup-bay-info-li"><span>Пакет, <span data-name="requests">1 000</span> запросов</span><span data-name="price">310 000 ₽</span></div>
          <div className="popup-bay-info-li"><span>Скидка</span><span><span data-name="saving">100</span> ₽</span></div>
        </div>
        {/*  */}
        <div className="popup-bay-entity" ref={inputWrapper} data-entity="data-entity">
          <div className="popup-bay-header">
            <h3>Данные организации</h3>
          </div>
          <div className="popup-bay-inputs">
            <div className="input">
              <div className="input-label">Наименование</div>
              <input
                type="text"
                name="name"
                value={companyInfo.secondSideName}
              />
              <div className="input-error">Обязательное поле</div>
            </div>
            <div className="input">
              <div className="input-label">ИНН</div>
              <input
                type="number"
                name="TIN"
                value={companyInfo.taxCode}
              />
              <div className="input-error">Обязательное поле</div>
            </div>
            <div className="input">
              <div className="input-label">КПП</div>
              <input
                type="number"
                name="КПП"
                value={companyInfo.kpp}
              />
              <div className="input-error">Обязательное поле</div>
            </div>
            <div className="input">
              <div className="input-label">Адрес</div>
              <input
                type="text"
                name="adress"
                value={companyInfo.legalAddress}
              />
              <div className="input-error">Обязательное поле</div>
            </div>
            {/* <div className="input">
              <div className="input-label">ОГРН</div>
              <input type="text" name="OGRN" data-required="validateText" />
              <div className="input-error">Обязательное поле</div>
            </div> */}
            {/* <div className="input">
              <div className="input-label">E-mail</div>
              <input type="text" name="email" data-required="validateEmail" />
              <div className="input-error">Обязательное поле</div>
            </div>
            <div className="input">
              <input type="tel" name="phone" placeholder="+7 ___ ___ __ __" data-required="validatePhone" />
              <div className="input-error">Обязательное поле</div>
            </div> */}
          </div>
          <div className="popup-bay-header">
            <h3>Банковские реквизиты</h3>
          </div>
          <div className="popup-bay-inputs">
            <div className="input">
              <div className="input-label">Расчётный счёт</div>
              <input
                type="number"
                name="account"
                value={companyInfo.accountId}
              />
              <div className="input-error">Обязательное поле</div>
            </div>
            <div className="input">
              <div className="input-label">Кор. счёт</div>
              <input
                type="number"
                name="cor"
                value={companyInfo.bankCorrAccount}

              />
              <div className="input-error">Обязательное поле</div>
            </div>

            <div className="input">
              <div className="input-label">БИК</div>
              <input
                type="number"
                name="BIC"
                value={companyInfo.bik}

              />
              <div className="input-error">Обязательное поле</div>
            </div>

            <div className="input">
              <div className="input-label">Название банка</div>
              <input
                type="text"
                name="bank"
                value={companyInfo.bankName}
              />
              <div className="input-error">Обязательное поле</div>
            </div>
          </div>
        </div>
        <div className="popup-bay-footer">
          <button
            className="btn btn-red"
            type="button"
            onClick={handlerBuyTp}
          >{radio === "Individual" ? "Оплатить онлайн" : "Выставить счёт"}
          </button>

          {/* <button
            className="btn btn-red"
            type="submit"
            data-entity="data-entity"
          >Выставить счёт
          </button> */}
          <label className="politic politic_required">
            <input
              type="checkbox"
              name="politic"
              checked={politicCheck}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPoliticCheck(!politicCheck)}
            />
            <span className="politic-wrap"><span className="politic-box">
              <svg>
                <use xlinkHref={`/sprite.svg#check`} />
              </svg>
            </span><span className="politic-text">Согласен и принимаю условия <a href="#" target="_blank">пользовательского соглашения</a> и ознакомлен с условиями <a href="#" target="_blank">личной оферты</a></span></span>
          </label>
        </div>
      </form>
    </div>
  )
}
export default Bay