import Navbar from '../components/Navbar'
import Header from '../components/Header'
import Call from '../components/popup/Call'
import Bay from '../components/popup/Bay'
import Password from '../components/popup/Password'
import Settings from '../components/Settings'


function Page5() {
    return (
    <Settings />
    )
}

export default Page5