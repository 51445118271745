import IMask from "imask";

let maskList = [];

const validation = {
  validatePhone(input) {
    const parent = input.closest(".input");
    const error = parent.querySelector(".input-error");
    const value = input.value
      .replaceAll(" ", "")
      .replaceAll("(", "")
      .replaceAll(")", "");

    if (!value.length) {
      parent.classList.add("error");
      if (error) error.textContent = "Обязательное поле";
      return true;
    } else if (value.length < 12) {
      parent.classList.add("error");
      if (error) error.textContent = "Введите актуальные данные";
      return true;
    } else {
      parent.classList.remove("error");
      return false;
    }
  },
  validateEmail(input) {
    // let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    // const parent = input.closest(".input");
    // const error = parent.querySelector(".input-error");

    // if (!input.value.length) {
    //   parent.classList.add("error");
    //   if (error) error.textContent = "Обязательное поле";
    //   return true;
    // } else if (!reg.test(input.value)) {
    //   parent.classList.add("error");
    //   if (error) error.textContent = "Введите актуальные данные";
    //   return true;
    // } else {
    //   parent.classList.remove("error");
    //   return false;
    // }
  },
  validateText(input) {
    // const parent = input.closest(".input");
    // const error = parent.querySelector(".input-error");
    // if (!input.value.length) {
    //   parent.classList.add("error");
    //   if (error) error.textContent = "Обязательное поле";
    //   return true;
    // } else if (input.value.length < 2) {
    //   parent.classList.add("error");
    //   if (error) error.textContent = "Введите актуальные данные";
    //   return true;
    // } else {
    //   parent.classList.remove("error");
    //   return false;
    // }
  },
  validateCheck(input) {
    const parent = input.closest(".input");

    if (input.value.length < 1) {
      parent.classList.add("error");
      return true;
    } else {
      parent.classList.remove("error");
      return false;
    }
  },
  validateFile(input) {
    const parent = input.closest(".input");
    const error = parent.querySelector(".input-error");
    const format = input.dataset.format;

    const file = input.files[0];
    console.log(file);

    if (!file) {
      parent.classList.add("error");
      error.textContent = "Обязательное поле";
      return true;
    }

    if (file.name.split(".").pop() !== format) {
      parent.classList.add("error");
      error.textContent = "Не правильынй формат";
      return true;
    }

    parent.classList.remove("error");
    return false;
  },
};

const maskOptions = {
  mask: "+7 #00 000 00 00",
  definitions: {
    "#": /[1-6]|[9]/,
  },
};

export class Form {
  constructor(props) {
    this.form = props;
    this.id = props.id;
    this.src = props.action;
    this.ya = props.dataset.ya;
    this.error = false;
    this.data = null;
    this.json = {};

    this.init();
  }
  init() {
    this.form.onsubmit = async (e) => {
      e.preventDefault();
      this.data = new FormData(this.form);

      this.validation();

      if (this.error) return;
    };
    this.focusInput();
  }
  validation() {
    this.error = this.form.querySelector('[name="politic"]')
      ? !this.form.querySelector('[name="politic"]').checked
      : false;
    this.data.delete("politic");

    for (let entries of this.data.entries()) {
      let input = this.form.querySelector(`[name='${entries[0]}']`);
      let valid = input.getAttribute("data-required");

      if (valid) {
        console.log(valid, input);
        this.error = validation[valid](input) ? true : this.error;
      }

      this.json[entries[0]] = entries[1];
    }
  }
  // async send() {
  //   let response = await fetch(this.src, {
  //     method: "POST",
  //     body: JSON.stringify(this.json),
  //   });

  //   if (response.ok) {
  //     console.log("Форма отправлена");
  //     this.form.classList.add("active");
  //     this.reset(this.form);

  //     if (this.ya) {
  //       this.ym(window.YANDEX_CODE, "reachGoal", this.ya);
  //       console.log("Цель: " + this.ya);
  //     }

  //     setTimeout(() => {
  //       this.form.classList.remove("active");
  //     }, 2000);
  //   } else {
  //     console.log("Ошибка HTTP: " + response.status);
  //   }
  // }
  reset(form) {
    form.reset();
    const inputs = [...form.querySelectorAll(".input.active")];
    inputs.forEach((i) => i.classList.remove("active"));
    console.log('focus')
    const focus = [...this.form.querySelectorAll(".input_focus")];
    focus.forEach((item) => item.classList.remove("input_focus"));

    maskList.forEach((item) => {
      item._unmaskedValue = null;
      item.updateValue();
    });

    const value = [...this.form.querySelectorAll('.input-value')]
    value.forEach(item => item.textContent = item.dataset.text)
  }
  focusInput() {
    const inputs = this.form.querySelectorAll(".input");
    inputs.forEach((item) => {
      const i = item.querySelector("[name]");
      i.addEventListener("focus", () => {
        item.classList.add("input_focus");
      });
      i.addEventListener("blur", () => {
        setTimeout(() => {
          const value = i.value.trim();
          if (!value.length){
            item.classList.remove("input_focus")
          } 
        });
      });
    });
  }
}

export function maskPhone(item) {
  const mask = IMask(item, maskOptions);

  item.onfocus = function () {
    mask.updateOptions({
      lazy: false,
    });
  };
  item.onblur = function () {
    mask.updateOptions({
      lazy: true,
    });
  };

  maskList.push(mask);
}

export function passwordVisible() {
  const inputsEye = [...document.querySelectorAll(".input-eye")];
  inputsEye.forEach((item) => {
    const input = item.closest(".input").querySelector("input");
    item.onclick = () => {
      item.classList.toggle("active");
      input.type = item.classList.contains("active") ? "text" : "password";
    };
  });
}

export function changeFile() {
  const files = [...document.querySelectorAll('[name="file"]')];
  files.forEach((item) => {
    const input = item.closest(".input");
    const error = input.querySelector(".input-error");
    const value = item.closest(".input").querySelector(".input-value");
    item.onchange = () => {
      const file = item.files[0];
      value.textContent = file.name;
      
      if (file.name.split(".").pop() === "xlsx") {
        input.classList.remove("error");
        error.textContent = "Обязательное поле";
      } else {
        input.classList.add("error");
        error.textContent = "Не правильынй формат";
      }
    };
  });
}
