import React, { FC } from 'react'
import { ITariffPlan } from '../api/models/responses';
interface IProps {
    range: React.RefObject<HTMLDivElement>;
    rangeValue: string;
    changeRange: (e: React.FormEvent<HTMLInputElement>) => void;
    currentRequests: Number;
    tariffPlans: ITariffPlan[];
    isPermanent: boolean;
}
const TariffRange: FC<IProps> = ({ range, tariffPlans, rangeValue, changeRange, currentRequests, isPermanent }) => {
    const getCurrentTariff = (requests: Number) => {
        return tariffPlans.filter((el) => el.amountOfRequests === requests)
    }
    const getMobileClass = (is: boolean) => {
        return (is
            ? "range-number-item _hidden-mobile"
            : "range-number-item"
        )
    }
    return (
        <div className="range-single">
            <div className="range-number" ref={range} >
                <div className="range-number-item" data-value="100"></div>
                <div className="range-number-item _hidden" data-value="200"></div>
                <div className="range-number-item _hidden" data-value="500"></div>
                <div className="range-number-item" data-value="1 000"></div>
                <div className="range-number-item _hidden" data-value="1 500"></div>
                <div className={getMobileClass(isPermanent)} data-value="2 000"></div>
                <div className="range-number-item _hidden" data-value="2 500"></div>
                <div className={getMobileClass(isPermanent)} data-value="3 000"></div>
                <div className="range-number-item _hidden" data-value="3 500"></div>
                <div className={getMobileClass(isPermanent)} data-value="4 000"></div>
                <div className="range-number-item _hidden" data-value="4 500"></div>
                <div className="range-number-item" data-value="5 000"></div>
                <div className="range-number-item _hidden" data-value="5 500"></div>
                <div className={getMobileClass(isPermanent)} data-value="6 000"></div>
                <div className="range-number-item _hidden" data-value="6 500"></div>
                <div className={getMobileClass(isPermanent)} data-value="7 000"></div>
                <div className="range-number-item _hidden" data-value="7 500"></div>
                <div className={getMobileClass(isPermanent)} data-value="8 000"></div>
                <div className="range-number-item _hidden" data-value="8 500"></div>
                <div className={getMobileClass(isPermanent)} data-value="9 000"></div>
                <div className="range-number-item _hidden" data-value="9 500"></div>
                <div className="range-number-item" data-value="10 000"></div>
            </div>
            <div className="range-progress"></div>
            <div className="range-thumb"></div>
            <div className="range-input">
                <input type="range" name="range" min="1" max="22" value={rangeValue} onInput={changeRange} />
            </div>
            <div className="package-plate">
                <div className="package-plate-item">
                    <div className="package-plate-span">Кол-во запросов</div>
                    <div className="package-plate-value"><span data-name="requests">1 000</span> шт</div>
                </div>
                <div className="package-plate-item">
                    <div className="package-plate-span">Стоимость 1-го запроса</div>
                    <div
                        className="package-plate-value">
                        {getCurrentTariff(currentRequests)[0]
                            ? Math.round(Number(getCurrentTariff(currentRequests)[0].price) / Number(currentRequests))
                            : 390
                        }
                        ₽
                    </div>
                </div>
                <div className="package-plate-item">
                    <div className="package-plate-span">Экономия<img src="img/fire.svg" alt="" /></div>
                    <div className="package-plate-value"><span data-saving>80 000</span> ₽</div>
                </div>
                <div className="package-plate-item">
                    <div className="package-plate-span">Период действия</div>
                    <div className="package-plate-value" data-name="validity">365</div>
                </div>
                {isPermanent &&
                    <div className="btn btn-red" data-modal="popup-bay" data-bay="data-bay" data-saving="100" data-requests="1 000" data-validity="12 месяцев" data-price="310 000 ₽">Купить</div>
                }
            </div>
        </div>
    )
}
export default TariffRange